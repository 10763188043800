@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hind:wght@400;500;600&display=swap");

body {
    margin: 0;
    padding: 0;
}

html {
    --gray-enjoy: #90a0b7;
    --black-enjoy: #192a3e;
    --green-enjoy: #b3cf42;
    --red-enjoy: #f7685b;
    --red-enjoy-2: #ff8383;
    --red-enjoy-3: #F96F7C;
    --blue-enjoy: #4576BF;
    --poppins: "Poppins", sans-serif;
    --montserrat: "Montserrat", sans-serif;
    --hind: "Hind", sans-serif;
    --roboto: "Roboto", sans-serif;
    --inter: "Inter", sans-serif;
    --lgray-enjoy: #8692a6;
    font-family: var(--montserrat);
    // -moz-outline-radius: 6px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    --margin: 8.3vw;
}

@media (max-width: 1439px) {
    html {
        --margin: 80px;
    }
}

@media (max-width: 1280px) {
    html {
        --margin: 40px;
    }
}

.noscrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.noscrollbar::-webkit-scrollbar {
    display: none;
}

.filler {
    flex-shrink: 1;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    background: none;
    margin: 0;
    padding: 0;
    border: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
    cursor: default;
}

button {
    cursor: pointer;
}

#root.app-root {
    position: absolute;
    top: 0;
    left: 0;
}

.spin::before {
    animation: 1.5s linear infinite spinner;
    animation-play-state: inherit;
    border: solid 5px #cfd0d1;
    border-bottom-color: var(--green-enjoy);
    border-radius: 50%;
    content: "";
    height: 40px;
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
    width: 40px;
    will-change: transform;
    top: 24px;
}

.spinn {
    animation: 1.5s linear infinite spinner;
    animation-play-state: inherit;
    border: solid 5px #cfd0d1;
    border-bottom-color: var(--green-enjoy);
    border-radius: 50%;
    box-sizing: border-box;
    position: relative;
    top: 20px;
    left: 20px;
    width: 30px;
    height: 30px;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;
    position: relative;
}